<template>
  <div id="the-document" class="" ref="document">
    <PrintPage v-if="project" :pageNumber="1" :pageCount="pageCount">
      <template v-slot:header>
        <slot>
          <PrintHeaderSSP />
        </slot>
      </template>

      <PrintProjectHeaderSSP :project="project">
        <template v-slot:header>
          <slot>
            Materialliste
          </slot>
        </template>
      </PrintProjectHeaderSSP>

      <div class="content-main first-page" ref="contentPage1">
        <div class="offer-positions">
          <div class="positions-grid sum mb">
            <div>Anzahl</div>
            <div>Beschreibung</div>
          </div>
          <div class="positions-grid" ref="gridlist">
            <template v-for="(item, index) in pages[0].items">
              <div class="item-count" :key="index + '_count'">
                {{ item.count }}
              </div>
              <div class="item-description" :key="index + '_description'">
                <span v-html="item.item.description"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </PrintPage>

    <template v-for="(page, index) in pagesAfterFirst">
      <PrintPage :key="index" v-if="project" :pageNumber="index + 2" :pageCount="pageCount">
        <template v-slot:header>
          <slot>
            <PrintHeaderSSP />
          </slot>
        </template>

        <div class="content-main page" ref="contentPage2">
          <div class="offer-positions">
            <div class="positions-grid sum mb">
              <div>Anzahl</div>
              <div>Beschreibung</div>
            </div>
            <div class="positions-grid">
              <template v-for="(item, index) in page.items">
                <div class="item-count" :key="index + '_count'">
                  {{ item.count }}
                </div>
                <div class="item-description" :key="index + '_description'">
                  <span v-html="item.item.description"></span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </PrintPage>
    </template>
  </div>
</template>

<script>
import PrintHeaderSSP from '@/components/Print/PrintHeaderSSP';
import PrintProjectHeaderSSP from '@/components/Print/PrintProjectHeaderSSP';
import { mapGetters, mapActions } from 'vuex';
import PrintPage from '@/components/Print/PrintPage';
import { ExtendedProject } from '@/helpers/projects';
import { formatSolarPanelsOverallPower, solarPlantOverallPower } from '@/helpers/projects';

export default {
  components: {
    PrintPage,
    PrintHeaderSSP,
    PrintProjectHeaderSSP,
  },
  props: {
    projectNumber: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      pages: [{ height: 0, items: [] }],
    };
  },
  computed: {
    ...mapGetters(['getProjectByNumber']),
    project() {
      const project = this.getProjectByNumber(this.number);
      if (project) {
        new ExtendedProject(project);
      }
      return project;
    },
    pageCount() {
      return this.pages.length;
    },
    pagesAfterFirst() {
      return this.pages.filter((item, index) => index > 0);
    },
  },
  methods: {
    ...mapActions(['fetchProjectByNumber']),

    /**
     * Assume a line height of 19px
     * First page content height: 773px
     * subsequent page content height: 911px
     * count lines in every item description, add up items to fit on each page
     */
    paginate() {
      const first_page_max_height = 770;
      const subsequent_page_max_height = 915;
      const line_height = 20;
      const offset = 15;
      const filteredItems = this.project.items.filter((item) => item.item.type === 'STANDARD');

      let currentPage = 0;
      let currentMaxHeight = first_page_max_height;
      filteredItems.map((item) => {
        let itemHeight =
          offset + line_height * (item.item.description.match(/<p>|<br>/g) || []).length;

        if (this.pages[currentPage].height + itemHeight > currentMaxHeight) {
          currentPage++;
          currentMaxHeight = subsequent_page_max_height;
          this.pages.push({ height: 0, items: [] });
        }

        this.pages[currentPage].height += itemHeight;
        this.pages[currentPage].items.push(item);
      });
    },

    formatSolarPanelsOverallPower,
    solarPlantOverallPower,
  },
  async mounted() {
    this.number = this.projectNumber;
    await this.fetchProjectByNumber(this.number);

    this.$nextTick(async () => {
      await this.paginate();
    });
  },
};
</script>

<style lang="scss">
$document-padding-left: 1cm;
$document-padding-right: 1cm;
$document-padding-top: 1cm;
$document-padding-bottom: 1cm;
$qcells-border: #999;

/** Document */
.document {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 19px;
}

/** Content Header */
.content-header {
  margin: 0.5cm $document-padding-right 0.5cm $document-padding-left;
}
.content-header h1 {
  font-size: 1.2em;
  font-weight: bold;
}

.content-header-line {
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
}
.date {
  font-size: 0.9em;
}

/** Content */
.content-main {
  margin: 0.5cm $document-padding-right 0.5cm $document-padding-left;
  display: flex;
  font-size: 0.9em;
  gap: 0.3cm;
}

.content-main.first-page {
  height: 20.5cm;
  overflow: visible;
}

.content-main.page {
  height: 24cm;
  overflow: visible;
}

/** System Overview */
.system-overview-grid {
  font-size: 0.9em;
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  row-gap: 0.1em;
  column-gap: 0.5em;
}
.system-overview-grid div:nth-child(even) {
  font-weight: bold;
}

/** Offer Positions */
.offer-positions {
  width: 100%;
}
.positions-grid {
  padding: 0.2em 1em;
  display: grid;
  grid-template-columns: 1cm auto;
  column-gap: 1em;
  row-gap: 1em;
}

.item-count {
  text-align: right;
  padding-right: 0.5em;
}

.positions-grid p {
  padding: 0;
  margin: 0;
}
.sum {
  font-weight: bold;
  border-bottom: 1px solid $qcells-border;
  border-top: 1px solid $qcells-border;
}

.mb {
  margin-bottom: 0.3cm;
}
.mt {
  margin-top: 0.3cm;
}
.last {
  border-top: 0;
}
</style>
